import powerUp from './powerup.svg';
import recharge from './recharge.svg';
import lock from './lock.svg';
import warp from './warp.svg';
import megaClick from './megaclick.svg';
import plusTap from './plustap.svg';


export default {
  ['energyBoost']: powerUp,
  ['megaClick']: megaClick,
  ['additionalTap']: plusTap,
  ['warpDrive']: warp,
  ['recharge']: recharge,
  ['locked']: lock,
};