import stories from './stories.json';
import global from './global.json';
import pages from './pages.json';
import navigation from './navigation.json';

export default {
  stories,
  global,
  pages,
  navigation,
};