import { styled } from '@app/stitches.config';

export const Root = styled('div', {
  fontWeight: 700,
  color: '#fff',
  marginBottom: '2rem',
  fontSize: '2.25rem',
  textAlign: 'center',
  // marginTop: '-36px'
  pointerEvents: 'none',
});