import { styled } from '@/app/stitches.config';

export const Subtitle = styled('h2', {
  marginTop: '1.75rem',
  color: '#FFF',
  textAlign: 'center',
  textShadow: '0px 0px 6.6px rgba(255, 255, 255, 0.49)',
  fontSize: '1.5rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '1.875rem',
});