import { styled } from '@app/stitches.config';

export const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  height: '100%',
});

export const Star = styled('div', {
  position: 'absolute',
  bottom: '2.5rem',
  left: '2.5rem',
});
